import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../../components/Layout'
import OurProfile from '../../components/OurProfile'
import ShorterContent from '../../components/ShorterContent'
import Footer from '../../components/Footer'
import Menu from '../../components/Menu'
import LocaleContext from '../../contexts/LocaleContext'

export default function Team({ data, pageContext }) {
    const {
        introText
      } = data.datoCmsAboutTeamPage

  return (
    <Layout locale={pageContext.locale}>
      <Menu
        transparent={false}
        menu={data.datoCmsNewMenu}
        socialMedia={data.datoCmsFooter.socialMedia}
      />
      <React.Fragment>
        <HelmetDatoCms>
          <title>Nordic Asia - Investment Advisory Group | About Nordic Asia</title>
        </HelmetDatoCms>

         <section className="container center-width prose">
            <ShorterContent>
                <div
                dangerouslySetInnerHTML={{
                    __html: introText,
                }}
                ></div>
            </ShorterContent>

          <Heading type="board" />
          <OurProfile
            members={data.allDatoCmsMember.edges.filter(({ node }) => node.positionType === 'Board' || node.positionType === 'Board and Management')}
            showInterviews={true}
          />

          <Heading type="management" />
          <OurProfile
            members={data.allDatoCmsMember.edges.filter(({ node }) => node.positionType === 'Management' || node.positionType === 'Board and Management')}
            showInterviews={true}
          />

          <h2>Team</h2>
          <OurProfile
            members={data.allDatoCmsMember.edges.filter(({ node }) => node.positionType === 'Team')}
            showInterviews={true}
          />

        </section>
        <Footer footer={data.datoCmsFooter} />
      </React.Fragment>
    </Layout>
  )
}

const Heading = (data) => {
  const locale = React.useContext(LocaleContext).language
  const sv = {'board': 'Styrelsen', 'management': 'Ledningen'}
  const eng = {'board':'Board of Directors', 'management': 'Management'}
  return (
    <h2>{locale=="sv" ? sv[data.type] : eng[data.type]}</h2>
  )
}


export const query = graphql`
  query ($locale: String) {
    datoCmsAboutTeamPage (locale: { eq: $locale }){
        introText
    }
    allDatoCmsMember(
        filter: { locale: { eq: $locale } }
        sort: { fields: position, order: ASC }
      ) {
        edges {
          node {
            name
            introductionNode {
              childMarkdownRemark {
                html
              }
            }
            id
            title
            photo {
              fixed(width: 300, height: 300, imgixParams: {crop: "faces", ar: "1", fit: "crop"}) {
                ...GatsbyDatoCmsFixed
              }
            }
            interview {
              slug
            }
            positionType
          }
        }
      }
      datoCmsNewMenu (locale: { eq: $locale }) {
        ...Menu
      }
      datoCmsFooter (locale: { eq: $locale }) {
        ...Footer
      }
  }
`
